.testimonial-wrapper {
    position: relative;
    // z-index: -1;
    background-repeat: no-repeat;
    background-position: bottom left;
    padding: 25px 0 150px;

    .section-head {
        .heading-bg {
            bottom: -35px;
            left: 0;
        }
    }

    .testimonial-slider-wrapper {
        margin-top: 0px;

        @media (max-width: 575px) {
            margin-top: 30px;
        }

        .slider-item {
            margin: 20px;
            padding-bottom: 50px;

            .slider-thumb {
                margin-top: 0px;
                min-height: 700px;

                @media (max-width: 991px) {
                    margin-top: 0;
                }

                @media (max-width: 767px) {
                    min-height: 300px;
                }
            }
        }

        .slider-card {
            display: inline-block;
            background-color: $color-secondary;
            padding: 60px;
            position: relative;
            min-height: 600px;

            @media (max-width: 767px) {
                padding: 30px;
                min-height: 300px;
            }

            &::before {
                content: "";
                width: 100%;
                height: 150px;
                background-image: url("../../images/icons/quote-icon.png");
                background-repeat: no-repeat;
                position: absolute;
                top: 30px;
                left: 30px;
            }

            .card-content {
                margin-top: 100px;

                @media (max-width: 767px) {
                    margin-top: 30px;
                }
            }
        }

        .card-heading {
            font-size: 20px;
            line-height: 30px;
            color: $color-white;
            font-weight: 700;
            font-family: $oxygen;
            margin-bottom: 20px;
        }

        .body-text {
            color: $color-gray-2;
        }

        .client-info {
            margin-top: 40px;
            .client-name {
                font-size: 20px;
                line-height: 26px;
                color: $color-white;
                font-weight: 500;
            }

            .client-role {
                font-size: 16px;
                line-height: 27px;
                color: $color-gray-2;
                font-weight: 400;
                font-family: $oxygen;
            }
        }

        // SLIDER CONTROLER

        .testimonial-slider-wrapper {
            position: relative;
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: 88%;
            z-index: 9999 !important;

            @media (max-width: 991px) {
                top: 88%;
            }
        }

        .swiper-button-prev {
            left: 41.5%;

            @media (max-width: 991px) {
                left: 69%;
            }
        }

        .swiper-button-next {
            right: 41%;

            @media (max-width: 991px) {
                right: 2%;
            }
        }

        .row > * {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
